<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.avatar {
  aspect-ratio: 1;
  border-radius: 6px !important;
  object-fit: cover;
}

.custom_font {
  font-size: 12px;
  text-align: justify;
}

.btn-cancel {
  background-color: #fff;
  color: #0779e4;
}

.btn-primary {
  background-color: #0779e4;
}

.danger {
  background-color: #fff;
  color: #e20404;
  border: 1px solid #ddd;
  font-weight: bold;
}

.label {
  color: inherit !important;
}

.is-loading:after {
  top: calc(50% - 0.5em);
  right: 0.75em;
}

.is-loading:after {
  position: absolute !important;
  right: 0.625em;
  top: 0.625em;
  z-index: 4;
}

.is-loading:after {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  content: "";
}
</style>
<template>
  <div v-bind:class="darkMode" class="card p-3 mb-3">
    <h4 class="pb-4">Account settings</h4>

    <b-media tag="li">
      <template #aside>
        <b-img v-if="typeof avatar == 'string'" :src="avatar" width="74" class="avatar"></b-img>
        <b-img v-else :src="require('../../../assets/img/loading.png')" width="74" class="avatar"></b-img>
      </template>
      <div class="ml-3">
        <h6 class="mt-0 mb-0 custom_font">Upload a new avatar</h6>
        <p class="mb-0 mb-2 custom_font">File size is limited to 1MB.</p>
        <button class="btn btn-primary btn-sm" @click="$refs.file.click()">Upload now</button>
      </div>
    </b-media>

    <div class="py-2" v-bind:class="darkModeText">
      <div class="row py-2">
        <div class="col-md-6 pb-3">
          <b-field label="First Name">
            <div v-if="user == null" class="spinner-border" role="status"></div>
            <b-input v-else v-model="user.fname"></b-input>
          </b-field>
        </div>
        <div class="col-md-6 pt-md-0 pb-3">
          <b-field label="Last Name">
            <div v-if="user == null" class="spinner-border" role="status"></div>
            <b-input v-else v-model="user.lname"></b-input>
          </b-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 pb-3">
          <b-field label="Phone Number">
            <div v-if="user == null" class="spinner-border" role="status"></div>
            <b-input v-else type="tel" v-model="user.phone"></b-input>
          </b-field>
        </div>
      </div>
      <div class="py-3 pb-4 border-bottom">
        <button class="btn btn-primary mr-3">Save Changes</button>
        <button class="btn btn-cancel" v-on:click="resetUser()">Cancel</button>
      </div>
      <div class="d-sm-flex align-items-center pt-3">
        <div>
          <b>Deactivate your account</b>
          <p style="custom_font">Details about your company account and password</p>
        </div>
        <div class="ml-auto">
          <button class="btn danger">Deactivate</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { darkModeStyle } from '../../../assets/js/darkMode';

export default {
  components: {
    ContentLoader
  },
  data() {
    return {
      user: null,
      avatar: null,
      darkMode: darkModeStyle('bg-dark text-light', 'bg-light text-dark'),
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
      darkModeText: darkModeStyle('text-light', 'text-dark'),
    }
  },
  methods: {
    resetUser() {
      this.user = { ...this.$store.getters.user };
      console.log({ ...this.$store.getters.user });
    },
  },
  computed: {
    userData: function() {
      if (typeof this.$store.getters.user == 'object') {
        this.user = { ...this.$store.getters.user };
      }
    },
    avatarData: function() {
      if (typeof this.$store.getters.avatar == 'string') {
        this.avatar = String(this.$store.getters.avatar);
      }
    }
  },
  watch: {
    '$store.getters.user': function(newVal) {
      if (newVal != null) {
        this.user = newVal;
      }
    },
    '$store.getters.avatar': function(newVal) {
      if (newVal != null) {
        this.avatar = newVal;
      }
    }
  },
  mounted() {
    this.userData;
    this.avatarData;
  },
  created() {
    this.userData;
    this.avatarData;
  }
}
</script>